.footerDiv {
  position: relative;
}
.infoDiv {
  position: absolute;
  top: 40%;
  left: 15%;
}

.infoDiv ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 25px;
  color: #fff;
}
.icony {
  margin: 0 10px;
  color: #fff;
}
.copyright {
  position: absolute;
  bottom: 0;
  right: 20px;
  color: #fff;
}
@media(max-width:1100px){
  .footerDiv{
    position: static;
  }
  .infoDiv{
    position: static;
  }
  .copyright{
    position: static;
    text-align: center;
  }
  .footerDiv img{
    display: none;
  }
 footer{
  margin-top: 20px;
  padding: 25px 0;
  background-color: #8bb2be;
 }
}