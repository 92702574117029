.works {
  padding: 150px;
  position: relative;
  overflow: hidden;
}
.works h1 {
  text-align: center;
  color: #fff;
  font-size: 40px;
  margin-bottom: 100px;
}
.flexy-div {
  display: flex;
  justify-content: space-between;
}
.f-flex {
  width: 33%;
  text-align: center;
  padding: 20px;
  margin-bottom: 35px;
}
.f-flex img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 13px;
}
.f-flex h4 {
  font-size: 25px;
  margin: 3px;
  color: #8bb2be;
}
.f-flex p {
  font-size: 20px;
  color: #fff;
  margin: 10px;
}
.bordered {
  border-right: 3px solid #fff;
  border-left: 3px solid #fff;
}
.illustration {
  width: 200px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  right: -8%;
  bottom: -10px;
  transform: rotate(-45deg);
}
.secillustration {
  width: 200px;
  height: 200px;
  background-color: #fff;
  position: absolute;
  left: -8%;
  bottom: -10px;
  transform: rotate(45deg);
}

@media (max-width: 1050px) {
  .works {
    padding: 75px;
  }
}

@media (max-width: 900px) {
  .works {
    padding: 40px;
  }
}

@media (max-width: 800px) {
  .works h1 {
    margin-bottom: 25px;
  }
}
@media (max-width: 700px) {
  .works {
    padding: 20px;
  }
  .flexy-div {
    flex-direction: column;
  }
  .f-flex {
    width: 100%;
    text-align: center;
    padding: 20px;
    margin-bottom: 20px;
  }
  .bordered {
    border: none;
  }
}
@media (max-width: 600px) {
  .illustration {
    right: -30%;
    bottom: -30px;
  }
  .secillustration {
    left: -30%;
    bottom: -30px;
  }
}
