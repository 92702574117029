@import url("https://fonts.googleapis.com/css2?family=Alegreya+Sans&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Alegreya Sans", sans-serif;
}
