.links-main {
  display: flex;
  justify-content: space-between;
  padding: 150px;
  background-color: #fff;
  position: relative;
}

.links-left {
  width: 50%;
}
.links-right {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  align-items: center;
  gap: 10px;
  border-left: 3px solid #053d57;
}

.links-right div {
  width: 70%;
}
.links-right div img {
  width: 100%;
  border-radius: 13px;
}
.links-left {
  margin-top: auto;
}
.links-left h4 {
  font-size: 45px;
  font-weight: bold;
  color: #053d57;
}
.links-left p {
  font-size: 25px;
  color: #053d57;
}
.links-left ul {
  list-style: disc;
}
.links-left h5 {
  font-size: 30px;
  color: #053d57;
}
.links-left ul li {
  color: #053d57;
  font-size: 25px;
  padding: 0 30px 0 0;
}

.effect{
    width: 500px;
    height: 500px;
    background-color: #8bb2be6d;
    position: absolute;
    top: 0;
    right: 50%;
    transform: skew(-45deg);
}

@media(max-width:900px){
  .links-main{
    padding: 70px;
  }
}

@media(max-width:800px){
  .links-main{
    flex-direction: column;
  }
  .links-left{
    width: 100%;
  }
  .links-right{
    width: 100%;
    border: none;
  }
}
@media(max-width:500px){
  .effect{
    display: none;
  }
  .links-main{
    padding: 25px;
  }
}