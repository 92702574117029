header {
  padding: 50px 130px;
  position: relative;
  background-image: url("../../images/conditioner.avif");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  overflow: hidden;
}

header::after {
  content: "";
  position: absolute;
  top: 0;
  left: -20%;
  width: 100%;
  height: 150%;
  background: rgb(25,77,101, 0.8);
  z-index: 0;
  transform: rotate(45deg);
}

.nav {
  height: 50px;
  width: 100%;
  position: relative;
  z-index: 3;
}

.nav > .nav-header {
  display: inline;
}
.logo {
padding-top: 50px;
 width: 350px;
}

.nav > .nav-header > .nav-title {
  display: inline-block;
  padding: 10px 10px 10px 10px;
  margin-top: -60px;
}

.nav > .nav-btn {
  display: none;
}

.nav > .nav-links {
  display: inline;
  position: fixed;
  right: 50px;
  padding: 10px 80px;
  float: right;
  font-size: 18px;
  background-color: rgb(25,77,101, 0.8);
  border-radius: 40px 20px;
  box-shadow: 2px 2px 10px #fff;
  transition: 0.1s linear;
}

.nav > .nav-links > a {
  display: inline-block;
  margin-left: 25px;
  margin-right: 25px;
  padding: 8px 16px;
  text-decoration: none;
  color: #fff;
  font-size: 25px;
  transition: 0.2s linear;
  border-radius: 13px;
  cursor: pointer;
}

.nav > .nav-links > a:hover {
  background-color: #194d64;
  transform: translateY(-5px);
}

.nav > #nav-check {
  display: none;
}

@media (max-width: 1300px) {
  .nav {
    padding: 0;
  }
  .nav > .nav-btn {
    display: inline-block;
    position: absolute;
    background-color: rgb(56, 55, 55);
    border-radius: 7px;
    right: 0px;
    top: 0px;
  }
  .nav > .nav-btn > label {
    display: inline-block;
    width: 50px;
    height: 50px;
    padding: 13px;
  }
  .nav > .nav-btn > label:hover,
  .nav #nav-check:checked ~ .nav-btn > label {
    background-color: #a4be7b2f;
  }
  .nav > .nav-btn > label > span {
    display: block;
    width: 25px;
    height: 10px;
    border-top: 2px solid #eee;
  }
  .nav > .nav-links {
    position: absolute;
    display: block;
    width: 100%;
    background-color:#a4be7b2f;
    height: 0px;
    transition: all 0.3s ease-in;
    overflow-y: hidden;
    top: 50px;
    left: 0px;
    padding: 0;
  }
  .nav > .nav-links > a {
    display: block;
    width: 100%;
    padding: 20px 0;
  }

  .nav > .nav-links > a:hover {
    background-color: rgba(204, 204, 204, 0);
  }

  .nav > #nav-check:not(:checked) ~ .nav-links {
    height: 0px;
  }
  .nav > #nav-check:checked ~ .nav-links {
    height: 100vh;
    overflow-y: auto;
    border-radius: 0 0 50px 50px;
    background: rgba(6, 7, 6, 0.671);
    box-shadow: 4px 4px 10px rgb(56, 55, 55);
  }
}

.hero {
  margin-top: 50px;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.hero-text {
  padding: 110px;
  text-align: center;
  color: #fff;
  position: relative;
  z-index: 2;
}
.hero-text h2 {
  font-size: 60px;
}

.hero-text p {
  padding: 0 100px;
  font-size: 25px;
}

.seperator {
  display: block;
  margin: 60px auto;
  width: 50%;
  height: 3px;
  background-color: #fff;
}
@media (max-width:950px){
  .hero-text{
    padding: 0;
  }
}

@media (max-width: 900px) {
  .hero-text {
    padding: 0;
  }
  header {
    padding: 50px;
  }
}
@media (max-width: 700px){
  header::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(25,77,101, 0.8);
    z-index: 0;
    transform: rotate(0);
  }
  
}

@media (max-width: 500px) {
  header{
    padding: 15px;
  }
  .logo{
    width: 250px;
  }
  .hero-text p {
    padding: 0;
  }
}
