.company {
  display: flex;
  padding: 150px;
  text-align: right;
  background-color: #fff;
  position: relative;
  overflow: hidden;
}
.left-company {
  width: 50%;
}
.flex-company {
  display: flex;
  justify-content: space-between;
}
.flex-company img {
  width: 50%;
}
.single {
  width: 100%;
  margin-top: 20px;
}
.single img {
  width: 100%;
  height: 150px;
}
.right-company {
  width: 50%;
}
.right-company h1 {
  font-size: 45px;
  margin: 0;
  margin-bottom: 25px;
  color: #053d57;
}
.right-company p {
  font-size: 20px;
  letter-spacing: normal;
}
.underline {
  width: 70%;
  height: 2px;
  display: block;
  background-color: #053d57;
  margin-left: auto;
}

@media(max-width:1050px){
  .company{
    padding: 100px;
  }
}

@media(max-width:900px){
  .company{
    padding: 50px;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .left-company{
    margin-top: 20px;
    width: 100%;
  }
  .single img{
    height: auto;
  }
  .right-company{
    width: 100%;
  }
}

