.aboutus {
  position: relative;
  background-color: #053d57;
  padding: 150px;
}
.people{
  color: #fff;
  font-size: 45px;
  padding: 30px;
  text-align: center;
}
.map {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 20px;
  z-index: 1;
}
.container {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: space-around;
  align-items: center;
  padding: 50px;
}
.leftCol {
  display: flex;
  justify-content: space-evenly;
}

.leftCol img {
  width: 35%;
  border-radius: 50% 20%;
  object-fit: cover;
}

.rightCol {
  display: flex;
  justify-content: space-evenly;
}
.rightCol img {
  width: 35%;
  border-radius: 20% 50%;
  object-fit: cover;
}

.divy {
  width: 45%;
}
.divy h1 {
  font-size: 50px;
  color: #fff;
}

.divy p {
  font-size: 25px;
  color: #fff;
}

.seperator {
  display: block;
  margin: 60px auto;
  width: 50%;
  height: 3px;
  background-color: #fff;
}

@media (max-width:1100px){
  .aboutus{
    padding: 70px;
  }
  .container{
    padding: 0;
  }
}

@media (max-width: 900px) {
  .container {
    padding: 0;
  }
  .leftCol,
  .rightCol {
    justify-content: space-between;
  }
}

@media (max-width: 700px) {
  .leftCol,
  .rightCol {
    flex-direction: column;
  }
  .leftCol img {
    margin: auto;
    border-radius: 30%;
    width: 70%;
  }
  .divy {
    margin: auto;
    width: 100%;
  }
  .rightCol img {
    margin: auto;
    border-radius: 30%;
    width: 70%;
  }
}
@media (max-width: 500px){
  .people{
    padding: 10px;
    font-size: 30px;
  }
}
