.main-sky {
  background-image: url("../../images/skycraper.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  padding: 150px;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}
.main-sky::after{
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(25,77,101, 0.8);
  z-index: 1;
  top: 0;
  left: 0;
}


.left-sky h1 {
  color: #fff;
  font-size: 65px;
  padding: 50px;
  position: relative;
  z-index: 5;
}
.right-sky {
  text-align: right;
  padding: 0 50px; 
  width: 50%;
  border-left: 3px solid #fff;
  position: relative;
  z-index: 5;
}
.right-sky h4 {
  font-weight: bold;
  font-size: 25px;
  color: #fff;
  margin: 20px 0;
}
.right-sky p {
  color: #fff;
  font-size: 25px;
  margin: 0;
}
@media (max-width: 1200px){
  .main-sky{
    padding: 75px;
  }
}

@media (max-width:990px){
  .left-sky h1{
    padding: 0;
  }
  .right-sky{
    padding: 20px;
  }
}

@media(max-width:850px){
  .main-sky{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .right-sky{
    text-align: center;
    border: none;
  }
}
@media(max-width:700px){
  .main-sky{
    padding: 25px;
  }
  .right-sky{
    width: 100%;
    padding: 0;
  }
  .left-sky{
    width: 100%;
  }
}
@media(max-width:450px){
  .left-sky h1{
    font-size: 35px;
  }
}