.info{
    padding: 150px;
    position: relative;
}

.info h1{
    text-align: center;
    color: #fff;
    font-size: 55px;
    margin-bottom: 100px;
}
.info-flex{
    display: flex;
    justify-content: space-between;
    z-index: 1;
}
.few-info{
    width: 19%;
    height: 320px;
    margin-bottom: 35px;
    padding: 15px;
    text-align: center;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 4px 4px 10px #8bb2be;
    transition: all 0.3s ease;
}
.few-info:hover{
  transform: translateY(-15px);
}
.few-info:nth-of-type(even){
    transform: translateY(50px);
}
.few-info:nth-last-of-type(even):hover{
    transform: scale(1.2);
}
.few-info p{
    margin: 0;
}
.few-info h4{
    font-weight: bold;
    margin: 10px;
}

@media(max-width:1310px){
  .few-info{
    height: 500px;
  }
}

@media(max-width:1100px){
    .info{
        padding: 75px;
    }
    .info-flex{
     flex-wrap: wrap;
    }
    .few-info{
        width: 33%;
    }
    .few-info:nth-of-type(even){
        transform: translateY(0);
    }
    .few-info:nth-last-of-type(even):hover{
        transform: scale(1);
    }
}
@media(max-width:750px){
    .info{
        padding: 40px;
    }
  .few-info{
    width: 50%;
    height: auto;
  }
}
@media(max-width:600px){
    .few-info{
        width: 100%;
    }
}