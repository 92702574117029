.latest-projects{
    margin: auto;
    padding-top: 100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.latest{
    width: 40%;
    text-align: center;
    padding: 50px;
    border-bottom: 2px solid #fff;
}
.latest img{
    width: 100%;
    height:350px;
    border-radius: 14px;
    box-shadow: 10px 10px 10px #8bb2be;
}
#reverse{
    box-shadow: -10px 10px 10px #8bb2be;
}
.latest-projects h1{
    width: 100%;
    font-size: 60px;
    color: #8bb2be;
    text-align: center;
    margin: 6px;
}
.latest-projects h2{
    width: 100%;
    font-size: 50px;
    color: #fff;
    text-align: center;
    margin:auto;
}

@media(max-width:1200px){
    .latest img{
        object-fit: cover;
    }
}

@media(max-width:1000px){
    .latest-projects{
        flex-wrap: wrap;
    }
    .latest{
        width: 100%;
    }
    .latest img{
        object-fit: contain;
        box-shadow: none;
    }
    #reverse{
        box-shadow: none;
    }
}
