.carousel{
  padding: 0 130px;
}
.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70vh;
}

.portfolio span:nth-of-type(1) {
  color: #8bb2be;
  font-size: 50px;
  font-weight: bold;
}

.portfolio span:nth-of-type(2) {
  color: #fff;
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 35px;
}

.portfolio .swiper {
  overflow: hidden!important;
}

.portfolio-slider {
  margin-top: 25px;
  width: 100%;
}

.portfolio-slider .swiper-slide {
  width: 25%!important;
}

.portfolio img {
  width: 20rem;
  height: 15rem;
  object-fit: cover;
  border-radius: 19px;
  box-shadow:4px 4px 5px #8bb2be;
}

.paragraph{
    display: flex;
    justify-content: center;
    padding: 30px 0;
}
.slider-text h4{
  font-size: 30px;
  color: #8bb2be;
  text-align: center;
  margin: 6px;
}
.slider-text p{
  font-size: 25px;
  color: #fff;
  text-align: center;
  margin:auto;
  width: 50%;
}

.sany{
    font-size: 60px;
    color: #5f8d4e;
}

@media(max-width:1000px){
  .carousel{
    padding: 50px;
  }
  .portfolio-slider .swiper-slide {
    width: 35%!important;
  }
}

@media(max-width:750px){
  .carousel{
    padding: 25px;
  }
  .paragraph{
    justify-content: space-between;
    padding: 0;
  }

  .portfolio-slider .swiper-slide {
    width: 50%!important;
  }
}

@media(max-width:550px){
  .paragraph{
    flex-direction: column;
  }
  .parone{
    width: 100%;
    border-right: none ;
  }
  .parsec{
    width: 100%;
  }
  .portfolio-slider .swiper-slide{
    width: 70%!important;
  }
  .slider-text p {
    width: 100%;
  }
}