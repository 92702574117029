.green {
  padding: 150px;
  position: relative;
  overflow: hidden;
}
.main-green {
  border: 25px solid #fff;
  box-shadow: 4px 4px 10px #8bb2be;
  padding: 25px;
  display: flex;
}
.img-green {
  display: flex;
  flex-direction: column;
  width: 70%;
  gap: 15px;
}
.img-grid {
  display: flex;
}
.img-grid div {
  width: 50%;
  margin-right: 15px;
}
.img-grid div img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 13px;
}
.green-text {
  display: flex;
  width: 50%;
  margin: auto;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.green-text p {
  font-size: 25px;
  color: #fff;
  text-align: center;
}
.decor{
  width: 150px;
  height: 150px;
  background-color: #fff;
  position: absolute;
  top: 15px;
  left: -5%;
  transform: rotate(45deg);
}
.secdecor{
  width: 150px;
  height: 150px;
  background-color: #fff;
  position: absolute;
  bottom: 15px;
  right: -5%;
  transform: rotate(45deg);
}
@media(max-width:1000px){
  .green{
    padding: 70px;
  }
}
@media(max-width:850px){
  .main-green{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .img-green{
    width: 100%;
  }
  .green-text{
    width: 100%;

  }
}

@media(max-width:650px){
  .img-grid{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .img-grid div {
    width: 100%;
  }
  .decor{
    top: 5px;
    left: -15%;
  }
  .secdecor{
    bottom: 5px;
    right: -15%;
  }
}
@media(max-width:500px){
  .main-green{
    padding: 0;
    border: 10px solid #fff;
  }
  .img-grid div {
    margin-right: 0;
  }
  .decor{
    top: 2px;
    left: -25%;
  }
  .secdecor{
    bottom: 2px;
    right: -25%;
  }
}